import Box from '@mui/material/Box'

import Navbar from './components/Navbar'
import Footer from './components/Footer'

import Image from 'mui-image'
import { Container, Stack, Grid } from '@mui/material'
import { useState, useEffect } from 'react'

import backgroundImage from './assets/images/backgroundImage.svg'
import travellingPeople from './assets/images/travellingPeople.png'
import personalisedIcon from './assets/images/personalisedIcon.svg'
import excluAcc from './assets/images/excluAccIcon.svg'
import culturalIcon from './assets/images/culturalIcon.svg'
import culinaryIcon from './assets/images/culinaryIcon.svg'
import blueIcon from './assets/images/blueIcon.svg'
import planeIcon from './assets/images/planeIcon.svg'
import youngMan from './assets/images/youngMan.png'
import olaDots from './assets/images/olaDots.svg'

const visions = [
  {
    text: 'Our Approach:',
    msg: 'We understand that each traveler’s distinct preferences & aspirations. Our approach revolves around you - your interests, your pace, your style. From the moment you reach out to us, we embark on a collaborative journey  to design a seamless itinerary that captures the essence of your destination while catering to your every need',
    style: {
      borderRadius: '24px 0px',
      borderTop: '1px solid #59B1E6',
      borderRight: '1px solid #59B1E6',
      borderBottom: '3px solid #59B1E6',
      borderLeft: '1px solid #59B1E6',
      background: 'var(--white, #FFF)'
    }
  },
  {
    text: 'Tailor-Made Experiences:',
    msg: `LuxiGo Travels is dedicated to curating tailor-made travel encounters that encompass genuineness, opulence, and deep cultural involvement. If you're in pursuit of a cozy escapade, a daring journey, or a serene hideaway, our skilled professionals leverage their worldwide expertise to meticulously select lodgings, experiences, and culinary delights that harmonize with your aspirations.`,
    style: {
      borderRadius: '24px 0px',
      borderTop: '1px solid #FC0',
      borderRight: '1px solid #FC0',
      borderBottom: '3px solid #FC0',
      borderLeft: '1px solid #FC0',
      background: 'var(--white, #FFF)'
    }
  },
  {
    text: 'Commitment to Excellence:',
    msg: `Excellence is not just a goal; it's our standard. We pride ourselves on our unwavering dedication to delivering exceptional service, ensuring your comfort and satisfaction at every turn. From crafting intricate itineraries to providing 24/7 support during your journey, We are  devoted to creating moments that linger in your memories long after your travels conclude`,
    style: {
      borderRadius: '24px 0px',
      borderTop: '1px solid #0CF',
      borderRight: '1px solid #0CF',
      borderBottom: '3px solid #0CF',
      borderLeft: '1px solid #0CF',
      background: 'var(--white, #FFF)'
    }
  }
]

function App () {
  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY)
    }

    // just trigger this so that the initial state
    // is updated as soon as the component is mounted
    // related: https://stackoverflow.com/a/63408216
    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      <Navbar scroll={scrollY} />
      <Box>
        <Box
          id='heroSection'
          sx={{
            backgroundImage: `url(${backgroundImage.src})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
            minHeight: '100vh',
            minWidth: '100vw',
            paddingTop: '80px'
          }}
        >
          <Container maxWidth='lg'>
            <Box
              sx={{
                display: { md: 'flex' },
                justifyContent: { md: 'space-between' }
              }}
            >
              <Stack spacing={2} justifyContent='center'>
                <Box
                  sx={{
                    fontWeight: 700,
                    color: 'primary.main',
                    display: 'flex',
                    justifyContent: { xs: 'center', md: 'flex-start' },
                    textAlign: { xs: 'center', md: 'left' },
                    fontSize: '25px'
                  }}
                >
                  Your journey, Your way
                </Box>
                <Box
                  sx={{
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                    display: 'flex',
                    justifyContent: { xs: 'center', md: 'flex-start' },
                    fontSize: { xs: '40px', md: '55px' },
                    textAlign: { xs: 'center', md: 'left' },
                    lineHeight: '110%'
                  }}
                >
                  Crafting Travel experiences that resonate with you
                </Box>
                <Box
                  sx={{
                    textAlign: { xs: 'center', md: 'left' }
                  }}
                >
                  LuxiGo Travels specializes in crafting bespoke travel
                  experiences that embrace authenticity, luxury, and cultural
                  immersion our experts use their global insights to handpick
                  accommodations, activities, and dining options that resonate
                  with your vision
                </Box>
              </Stack>
              <Box>
                <Image
                  src={travellingPeople}
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>
            </Box>
            <Box id='about'>
              <Box
                sx={{
                  paddingTop: { xs: '60px', md: '200px' },
                  color: 'primary.main',
                  fontSize: { xs: '30px', md: '40px' },
                  fontWeight: 900,
                  fontFamily: 'Roboto',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                About us
              </Box>
              <Box
                sx={{
                  position: 'absolute',
                  right: '10%',
                  top: '120%'
                }}
              >
                <Image
                  src={olaDots}
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>
              <Box
                sx={{
                  paddingTop: '20px',
                  textAlign: 'center',
                  fontSize: { md: '32px' },
                  lineHeight: { md: '48.51px' }
                }}
              >
                LuxiGo Travels is a premier travel concierge company dedicated
                to crafting extraordinary journeys that transcend expectations.
                With a passion for exploration and a commitment to personalized
                service, we stand as your trusted partner in creating immersive
                travel experiences tailored to your unique desires
              </Box>
            </Box>
            <Box id='team'>
              <Box
                sx={{
                  paddingTop: '60px',
                  color: 'primary.main',
                  fontSize: { xs: '30px', md: '40px' },
                  fontWeight: 900,
                  fontFamily: 'Roboto',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                Our team
              </Box>
              <Box
                sx={{
                  paddingTop: '20px',
                  textAlign: 'center',
                  fontSize: { md: '32px' },
                  lineHeight: { md: '48.51px' }
                }}
              >
                At LuxiGo Travels, our team of seasoned travel experts brings
                together a wealth of knowledge, creativity, and a deep
                appreciation for wanderlust. With years of industry experience,
                we thrive on turning dreams into reality, meticulously curating
                every detail to ensure your journey is nothing short of
                remarkable
              </Box>
            </Box>
            <Box
              id='tailor'
              sx={{
                paddingTop: '60px'
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  left: '5%',
                  top: '210%',
                  zIndex: 1
                }}
              >
                <Image
                  src={olaDots}
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>
              <Grid container spacing={2}>
                {visions.map(({ text, msg, style }, index) => (
                  <Grid key={index} item xs={12} md={4}>
                    <Box
                      sx={{
                        ...style,
                        height: '450px',
                        padding: '25px'
                      }}
                    >
                      <Box
                        sx={{
                          fontFamily: 'Roboto',
                          fontSize: '24px',
                          fontWeight: 900,
                          width: { md: '50%' },
                          color: 'primary.main'
                        }}
                      >
                        {text}
                      </Box>
                      <Box
                        sx={{
                          paddingTop: '20px',
                          fontSize: { md: '16px' }
                        }}
                      >
                        {msg}
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box
              id='easyFast'
              sx={{
                paddingTop: '60px'
              }}
            >
              <Box
                sx={{
                  fontsize: '18px',
                  fontWeight: 900
                }}
              >
                Easy and Fast
              </Box>
              <Box
                sx={{
                  fontFamily: 'Roboto',
                  fontSize: { xs: '30px', md: '50px' },
                  fontWeight: 900,
                  paddingTop: '20px',
                  lineHeight: 'normal'
                }}
              >
                Book your next trip <br /> with us
              </Box>
            </Box>
            <Box
              sx={{
                paddingTop: { xs: '40px', md: '20px' },
                display: { md: 'flex' },
                justifyContent: { md: 'space-between' },
                paddingBottom: { md: '50px' }
              }}
            >
              <Stack
                sx={{
                  alignSelf: 'center'
                }}
                spacing={4}
              >
                <Stack direction='row' spacing={2}>
                  <Box
                    sx={{
                      width: '20%',
                      alignSelf: 'center'
                    }}
                  >
                    <Image
                      src={personalisedIcon}
                      style={{ width: '47px', height: '48px' }}
                    />
                  </Box>
                  <Stack
                    sx={{
                      alignSelf: 'center',
                      width: { md: '100%' }
                    }}
                  >
                    <Box
                      sx={{
                        color: '#5E6282',
                        fontWeight: 900,
                        fontStyle: 'normal',
                        fontSize: { xs: '16px', md: '18px' },
                        width: { md: '350px' }
                      }}
                    >
                      Personalized Itineraries
                    </Box>
                    <Box
                      sx={{
                        fontSize: { xs: '14px', md: '16px' }
                      }}
                    >
                      Designing tailor-made journeys that reflect your unique
                      preferences
                    </Box>
                  </Stack>
                </Stack>
                <Stack direction='row' spacing={2}>
                  <Box
                    sx={{
                      width: '20%',
                      alignSelf: 'center'
                    }}
                  >
                    <Image
                      src={excluAcc}
                      style={{ width: '47px', height: '48px' }}
                    />
                  </Box>
                  <Stack
                    sx={{
                      alignSelf: 'center',
                      width: '100%'
                    }}
                  >
                    <Box
                      sx={{
                        color: '#5E6282',
                        fontWeight: 900,
                        fontStyle: 'normal',
                        fontSize: { xs: '16px', md: '18px' },
                        width: { md: '350px' }
                      }}
                    >
                      Exclusive Accommodation Selection
                    </Box>
                    <Box
                      sx={{
                        fontSize: { xs: '14px', md: '16px' }
                      }}
                    >
                      Curating luxurious stays for an unmatched blend of
                      opulence and comfort
                    </Box>
                  </Stack>
                </Stack>
                <Stack direction='row' spacing={2}>
                  <Box
                    sx={{
                      width: '20%',
                      alignSelf: 'center'
                    }}
                  >
                    <Image
                      src={culturalIcon}
                      style={{ width: '47px', height: '48px' }}
                    />
                  </Box>
                  <Stack
                    sx={{
                      alignSelf: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        color: '#5E6282',
                        fontWeight: 900,
                        fontStyle: 'normal',
                        fontSize: { xs: '16px', md: '18px' },
                        width: { md: '350px' }
                      }}
                    >
                      Cultural Immersion Experiences
                    </Box>
                    <Box
                      sx={{
                        fontSize: { xs: '14px', md: '16px' }
                      }}
                    >
                      Creating connections through immersive cultural
                      explorations and local interactions
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
              <Box
                sx={{
                  width: { md: '70%' }
                }}
              >
                <Image
                  src={youngMan}
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>
              <Stack
                sx={{
                  alignSelf: 'center',
                  paddingBottom: { xs: '50px', md: '0px' }
                }}
                spacing={4}
              >
                <Stack direction='row' spacing={2}>
                  <Box
                    sx={{
                      width: '20%',
                      alignSelf: 'center'
                    }}
                  >
                    <Image
                      src={culinaryIcon}
                      style={{ width: '47px', height: '48px' }}
                    />
                  </Box>
                  <Stack
                    sx={{
                      alignSelf: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        color: '#5E6282',
                        fontWeight: 900,
                        fontStyle: 'normal',
                        fontSize: { xs: '16px', md: '18px' },
                        width: { md: '360px' }
                      }}
                    >
                      Culinary Delights
                    </Box>
                    <Box
                      sx={{
                        fontSize: { xs: '14px', md: '16px' }
                      }}
                    >
                      Guiding you through gastronomic adventures that capture
                      each destination's essence
                    </Box>
                  </Stack>
                </Stack>
                <Stack direction='row' spacing={2}>
                  <Box
                    sx={{
                      width: '20%',
                      alignSelf: 'center'
                    }}
                  >
                    <Image
                      src={blueIcon}
                      style={{ width: '47px', height: '48px' }}
                    />
                  </Box>
                  <Stack
                    sx={{
                      alignSelf: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        color: '#5E6282',
                        fontWeight: 900,
                        fontStyle: 'normal',
                        fontSize: { xs: '16px', md: '18px' },
                        width: { md: '350px' }
                      }}
                    >
                      Expert Guided Tours
                    </Box>
                    <Box
                      sx={{
                        fontSize: { xs: '14px', md: '16px' }
                      }}
                    >
                      Exploring landmarks, nature, and history with insights
                      from seasoned guides
                    </Box>
                  </Stack>
                </Stack>
                <Stack direction='row' spacing={2}>
                  <Box
                    sx={{
                      width: '20%',
                      alignSelf: 'center'
                    }}
                  >
                    <Image
                      src={planeIcon}
                      style={{ width: '47px', height: '48px' }}
                    />
                  </Box>
                  <Stack
                    sx={{
                      alignSelf: 'center'
                    }}
                  >
                    <Box
                      sx={{
                        color: '#5E6282',
                        fontWeight: 900,
                        fontStyle: 'normal',
                        fontSize: { xs: '16px', md: '18px' },
                        width: { md: '350px' }
                      }}
                    >
                      Seamless Travel Planning
                    </Box>
                    <Box
                      sx={{
                        fontSize: { xs: '14px', md: '16px' }
                      }}
                    >
                      Comprehensive planning for a worry-free journey, focusing
                      on unforgettable moments
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Container>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}

export default App
