import Image from 'mui-image'
import EmailIcon from '@mui/icons-material/Email'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import WhatsAppIcon from '@mui/icons-material/WhatsApp'
import InstagramIcon from '@mui/icons-material/Instagram'
import { Box, Container, Divider, Stack } from '@mui/material'

import whiteLogo from '../assets/images/whiteLogo.svg'

const socialIcons = [
  {
    icon: <EmailIcon />,
    text: 'info@luxigoltd.com',
    href: 'mailto: info@luxigoltd.com'
  },
  {
    icon: <LocalPhoneIcon />,
    text: '08154165386',
    href: 'tel: 2348154165386'
  },
  {
    icon: <WhatsAppIcon />,
    text: '07042977086',
    href: 'https://wa.me/07042977086'
  },
  {
    icon: <InstagramIcon />,
    text: 'luxigo_travels',
    href: 'https://www.instagram.com/luxigo_travels/'
  }
]

const navItems = [
  { text: 'Home', link: 'heroSection' },
  { text: 'About Us', link: 'about' },
  { text: 'Our Team', link: 'team' },
  { text: 'Contact Us', link: 'contact' }
]

export default function Footer () {
  const scrollToSection = element => {
    var section = document.getElementById(element)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <Box
      id='contact'
      sx={{
        backgroundColor: '#00BBEA',
        minHeight: '30vh'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '39px',
          fontFamily: 'Roboto',
          fontSize: '30px',
          color: 'white',
          fontWeight: 900
        }}
      >
        Contact Us
      </Box>
      <Container maxWidth='lg'>
        <Box
          sx={{
            paddingTop: '5px'
          }}
        >
          <Divider
            sx={{
              border: '1px solid rgba(255, 255, 255, 0.34)',
              backgroundColor: 'white',
              opacity: 0.6
            }}
          />
        </Box>
        <Box>
          <Box
            sx={{
              display: { md: 'flex' },
              justifyContent: { md: 'space-between' },
              padding: { md: '50px 0 50px 0' }
            }}
          >
            <Box
              sx={{
                display: { xs: 'flex', md: 'block' },
                justifyContent: 'center'
              }}
            >
              <Box
                sx={{
                  paddingTop: '20px',
                  width: '100%'
                }}
              >
                <Image
                  src={whiteLogo}
                  style={{ width: '100%', height: '100%' }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                paddingTop: '40px',
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Stack>
                {socialIcons.map(({ icon, text, href }, index) => (
                  <Stack
                    key={index}
                    sx={{
                      color: 'white'
                    }}
                    direction='row'
                    spacing={2}
                  >
                    <Box>{icon}</Box>
                    <Box
                      component='a'
                      target='__blank'
                      href={href}
                      sx={{
                        fontSize: '14px',
                        fontWeight: 900,
                        color: 'white',
                        paddingTop: '3px'
                      }}
                    >
                      {text}
                    </Box>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Box>
          <Box
            sx={{
              paddingTop: '10px',
              paddingBottom: '30px'
            }}
          >
            <Divider
              sx={{
                border: '1px solid rgba(255, 255, 255, 0.34)',
                backgroundColor: 'white',
                opacity: 0.6
              }}
            />
          </Box>
          <Box
            sx={{
              display: { md: 'flex' },
              justifyContent: { md: 'space-between' },
              paddingBottom: { md: '40px' }
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Stack spacing={{ xs: 1, md: 2 }} direction={{ md: 'row' }}>
                {navItems.map((item, index) => (
                  <Box
                    key={index}
                    component='a'
                    href='javascript:void(0);'
                    onClick={() => scrollToSection(item.link)}
                    sx={{
                      textAlign: 'center',
                      color: 'white',
                      fontWeight: 900
                    }}
                  >
                    {item.text}
                  </Box>
                ))}
              </Stack>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                fontFamily: 'Roboto',
                fontWeight: 900,
                color: 'white',
                paddingTop: { xs: '20px', md: '0px' },
                paddingBottom: { xs: '20px', md: '0px' }
              }}
            >
              Copyright © 2023 • Luxigo Travels
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
